<template>
  <YLayoutBodyMenu :menu="[
      {path:'/user/finance/wait',title:'提现打款'},
      {path:'/user/finance/log',title:'打款记录'},
  ]">
    <div slot="left">
      <el-menu router :default-active="$route.path" style="height: 100%">
        <el-menu-item :route="{name:'financeWait'}">提现打款</el-menu-item>
        <el-menu-item :route="{name:'financeLog'}">打款记录</el-menu-item>
      </el-menu>
    </div>
    <router-view/>
  </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>